"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _exportModule = _interopRequireDefault(require("./export.module.scss"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var variables = {};
Object.keys(_exportModule["default"]).forEach(function (item) {
  var value = _exportModule["default"][item];
  switch (item) {
    case 'icon-sizes':
      value = value.split(',').map(function (item) {
        return parseInt(item.trim());
      }).filter(function (item) {
        return !!item;
      });
      break;
    case 'colors':
    case 'gradients':
    case 'elevations':
    case 'radius':
    case 'button-sizes':
    case 'button-variants':
    case 'tag-sizes':
    case 'tag-variants':
    case 'themes':
      value = value.split(',').map(function (item) {
        return item.trim().replace(/"/g, '');
      }).filter(function (item) {
        return !!item;
      });
      break;
    default:
  }
  variables[item] = value;
});
var _default = exports["default"] = variables;
module.exports = exports.default;