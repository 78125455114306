"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = cls;
var _clsx = _interopRequireDefault(require("clsx"));
var _colorModule = _interopRequireDefault(require("./utils/color.module.scss"));
var _displayModule = _interopRequireDefault(require("./utils/display.module.scss"));
var _flexModule = _interopRequireDefault(require("./utils/flex.module.scss"));
var _positionModule = _interopRequireDefault(require("./utils/position.module.scss"));
var _spacingModule = _interopRequireDefault(require("./utils/spacing.module.scss"));
var _fontModule = _interopRequireDefault(require("./utils/font.module.scss"));
var _elevationModule = _interopRequireDefault(require("./utils/elevation.module.scss"));
var _gradientModule = _interopRequireDefault(require("./utils/gradient.module.scss"));
var _radiusModule = _interopRequireDefault(require("./utils/radius.module.scss"));
var _themeModule = _interopRequireDefault(require("./utils/theme.module.scss"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var globalStyles = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _themeModule["default"]), _colorModule["default"]), _displayModule["default"]), _flexModule["default"]), _positionModule["default"]), _spacingModule["default"]), _fontModule["default"]), _elevationModule["default"]), _gradientModule["default"]), _radiusModule["default"]);
function cls() {
  var cssClasses = _clsx["default"].apply(void 0, arguments);
  return cssClasses ? cssClasses.split(/ |\n|\r/).map(function (key) {
    return typeof globalStyles[key] === 'string' ? globalStyles[key] : key;
  }).join(' ').trim() : '';
}
module.exports = exports.default;